import { Text } from 'assets/components/text';
import { makeStyles } from 'assets/theme';
import { FunctionComponent, PropsWithChildren } from 'react';
import { View } from 'react-native';

export const RowItemRender: FunctionComponent<
  PropsWithChildren<RowItemRenderProps>
> = ({ title, value, children }) => {
  const styles = useStyles();
  return (
    <View style={styles.container}>
      <View style={styles.firstColumnContent}>
        <Text style={styles.title}>{title}</Text>
      </View>

      <View style={{ flex: 4 }}>
        {value && <Text style={styles.value}>{value}</Text>}
        {children}
      </View>
    </View>
  );
};

interface RowItemRenderProps {
  title: string;
  value?: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'row',
    gap: 15,
    alignItems: 'flex-start',
    borderBottomColor: theme.palette.gray[200],
    borderBottomWidth: 1,
    padding: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
  },
  firstColumnContent: {
    flex: 1,
    flexDirection: 'row',
  },
  title: {
    ...theme.fonts.regular,
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.gray[900],
    fontWeight: '600',
  },
  value: {
    ...theme.fonts.regular,
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[700],
  },
}));
