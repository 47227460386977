import {
  AppointmentTypeDto,
  BookingDto,
} from '@digitalpharmacist/appointment-service-client-axios';
import { DatesSetArg } from '@fullcalendar/core';
import { logError } from 'assets/logging/logger';
import { getText } from '../../../../../packages/assets/localization/localization';
import AppointmentService from '../../api/AppointmentService';
import { isDateBefore } from '../../common/datetime-utils';
import { useToast } from '../../common/hooks/useToast';
import { useAppStateStore } from '../../store/app-store';
import { useServicesListState } from '../services-list/services-list-store';
import { useAppointmentsCalendarState } from './appointments-calendar-store';

const errorOccurred = (error: any, errorMessage?: string) => {
  const { toast } = useToast();
  const message = errorMessage
    ? errorMessage
    : getText('error-cancel-appointment');

  logError(error);
  useAppointmentsCalendarState.setState({
    error: {
      message: message,
    },
    status: 'error',
  });

  toast('Error', { type: 'error', content: message });
};

export const getAppointments = async (
  event: Pick<DatesSetArg, 'startStr' | 'endStr'>,
  appointmentTypeId?: string,
) => {
  const pharmacyId = useAppStateStore.getState().pharmacyId;
  const locationId = useAppStateStore.getState().locationId;

  const startDate = new Date(event.startStr).toISOString();
  const endDate = new Date(event.endStr).toISOString();

  useAppointmentsCalendarState.setState({
    status: 'loading',
    dateRange: {
      startDate,
      endDate,
    },
  });

  try {
    const { results, total } = await AppointmentService.getBookings({
      pharmacyId,
      offset: 0,
      limit: 500,
      orderBy: 'ASC',
      locationId,
      minStartDate: startDate,
      maxEndDate: endDate,
      appointmentTypeId,
    });

    useAppointmentsCalendarState.setState({
      status: 'idle',
      appointments: results,
      totalCount: total,
    });
  } catch (error) {
    errorOccurred(error);
  }
};

export const groupByUniqueServicesAndCount = (events?: BookingDto[]) => {
  const services = useServicesListState.getState().services;

  if (services && events?.length) {
    const totalAppointmentServices = groupById(events, services);

    const upcomingEvents = events.filter((booking) =>
      isDateBefore(new Date().toISOString(), booking.startTime),
    );

    const upcomingAppointmentServices = groupById(upcomingEvents, services);

    return { totalAppointmentServices, upcomingAppointmentServices };
  }
};

const groupById = (data: BookingDto[], services?: AppointmentTypeDto[]) => {
  if (services && data.length) {
    return Object.values(
      data.reduce<Record<string, ServiceType>>((prevValue, currentValue) => {
        const currentKey = currentValue.appointment_type_id;
        const serviceTitle = services.find(
          (el) => el.id === currentValue.appointment_type_id,
        );

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (prevValue[currentKey]) {
          prevValue[currentKey].count = prevValue[currentKey].count + 1;
        } else {
          prevValue[currentKey] = {
            title: serviceTitle?.title ?? 'Unknown',
            count: 1,
          };
        }

        return prevValue;
      }, {}),
    );
  }
};

interface ServiceType {
  title: string;
  count: number;
}
