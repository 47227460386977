import React, { FunctionComponent, useCallback, useRef, useState } from 'react';
import { View } from 'react-native';
import {
  GridReadyEvent,
  GridApi,
  ColDef,
  ColGroupDef,
} from '@ag-grid-community/core';
import { useFocusEffect } from '@react-navigation/native';
import {
  BookingDto,
  BookingPageDto,
  BookingStatus,
} from '@digitalpharmacist/appointment-service-client-axios';
import { makeStyles, useTheme } from 'assets/theme';
import { DataGrid } from 'assets/components/data-grid';
import { ClockIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';

import NoResultsOverlay from '../../components/NoResultsOverlay';
import AppointmentService from '../../api/AppointmentService';
import { LoadingOverlay } from '../../components/LoadingOverlay';
import {
  calculatePatientAge,
  DEFAULT_UTC_OFFSET,
  formatDate,
  formatUTCToRelative,
} from '../../common/datetime-utils';
import { ColoredBadge } from '../../components/ColoredBadge';
import {
  minutesDifference,
  STATUS_STYLE_MAPPING,
} from '../appointments-list/appointments-list.utils';
import { EllipsisTextRendererTooltip } from '../../common/EllipsisTextRendererTooltip';
import { Tooltip } from '../../components/Tooltip';

const NoLoading: FunctionComponent = () => {
  return <View style={{ height: '100%' }}></View>;
};

const StatusRenderer = (props: { data: BookingItem }) => {
  const styles = useStyles();
  const rowData = props.data;

  return (
    <View style={styles.cellContainer}>
      <ColoredBadge
        {...STATUS_STYLE_MAPPING[rowData.status]}
        textStyle={styles.badgeText}
      />
    </View>
  );
};

export const AppointmentsCancelledList: FunctionComponent<
  AppointmentsCancelledListProps
> = (props) => {
  const theme = useTheme();
  const [paginatedDatasource] = useState(
    AppointmentService.getAppointmentsPaginatedDatasource(),
  );
  const gridApiRef = useRef<GridApi>();
  const [tooltipData, setTooltipData] = useState<TooltipProps>();

  const [columnDefs] = useState([
    {
      field: 'full_name',
      headerName: getText('name'),
      cellRenderer: ({ value, rowIndex }: CellRendererProps) => (
        <EllipsisTextRendererTooltip
          value={value}
          rowIndex={rowIndex}
          setTooltipData={setTooltipData}
        />
      ),
    },
    {
      field: 'patient_record_date_of_birth',
      headerName: getText('birthdate'),
      cellRenderer: ({ value, rowIndex }: CellRendererProps) => (
        <EllipsisTextRendererTooltip
          value={`${formatDate(value)} (${calculatePatientAge(value)})`}
          rowIndex={rowIndex}
          setTooltipData={setTooltipData}
        />
      ),
    },
    {
      field: 'title',
      headerName: getText('service'),
      cellRenderer: ({ value, rowIndex }: CellRendererProps) => (
        <EllipsisTextRendererTooltip
          value={value}
          rowIndex={rowIndex}
          setTooltipData={setTooltipData}
        />
      ),
    },
    {
      field: 'appointment_group_type',
      headerName: getText('service-category'),
      cellRenderer: ({ value, rowIndex }: CellRendererProps) => (
        <EllipsisTextRendererTooltip
          value={value}
          rowIndex={rowIndex}
          setTooltipData={setTooltipData}
        />
      ),
    },
    {
      maxWidth: 140,
      field: 'duration',
      headerName: getText('duration'),
      cellRenderer: ({ value, rowIndex }: CellRendererProps) => (
        <EllipsisTextRendererTooltip
          value={`${value} min`}
          rowIndex={rowIndex}
          setTooltipData={setTooltipData}
        />
      ),
    },
    // TODO: This is not yet implemented on the back-end, the hard-coded value needs to be replaced with the API data.
    {
      maxWidth: 150,
      field: 'location',
      headerName: getText('venue'),
      cellRenderer: ({ value, rowIndex }: CellRendererProps) => (
        <EllipsisTextRendererTooltip
          value={getText('venue-in-person')}
          rowIndex={rowIndex}
          setTooltipData={setTooltipData}
        />
      ),
    },
    {
      maxWidth: 150,
      field: 'status',
      headerName: getText('status'),
      cellRenderer: StatusRenderer,
      cellStyle: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
      },
    },
    {
      maxWidth: 150,
      field: 'startTime',
      cellRenderer: ({ value, rowIndex }: CellRendererProps) => (
        <EllipsisTextRendererTooltip
          value={formatUTCToRelative(
            value,
            undefined,
            true,
            'US',
            DEFAULT_UTC_OFFSET,
          )}
          rowIndex={rowIndex}
          setTooltipData={setTooltipData}
        />
      ),

      headerName: getText('time'),
      cellStyle: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
      headerClass: 'data-grid-header-right-aligned',
    },
  ] as (ColDef | ColGroupDef)[]);

  useFocusEffect(
    useCallback(() => {
      gridApiRef.current?.dispatchEvent({ type: 'filterChanged' });
    }, []),
  );

  return (
    <>
      <DataGrid
        gridOptions={{
          onGridReady(event: GridReadyEvent) {
            gridApiRef.current = event.api;
          },
          columnDefs: columnDefs,
          enableCellTextSelection: true,
          suppressRowClickSelection: true,
          suppressMovableColumns: true,
          suppressContextMenu: true,
          defaultColDef: { sortable: false, menuTabs: [] },
          pagination: true,
          paginationPageSize: 10,
          cacheBlockSize: 10,
          rowModelType: 'serverSide',
          serverSideStoreType: 'partial',
          serverSideDatasource: paginatedDatasource,
          loadingCellRendererSelector: (params) => {
            // This is a temporary workaround to only show one loading indicator per table row
            // What we want to do in the future is create a RowLoadingPlaceholder which would indicate the loading state for the row
            if (
              params.rowIndex == 0 ||
              (params.rowIndex % 5 == 0 && params.rowIndex % 10 !== 0)
            ) {
              return {
                component: LoadingOverlay,
              };
            }

            return {
              component: NoLoading,
            };
          },
          context: {
            transformResponse(params: BookingPageDto) {
              const { results, total } = params;

              const resultsWithUserData = results.map((x) => {
                return {
                  ...x,
                  full_name: `${x.patient_record_first_name} ${x.patient_record_last_name}`,
                  duration: minutesDifference(x.endTime, x.startTime),
                };
              });

              return {
                total,
                results: resultsWithUserData,
              };
            },
            transformRequest(params) {
              return {
                ...params,
                status: BookingStatus.Cancelled,
              };
            },
          },
          noRowsOverlayComponent: () => (
            <NoResultsOverlay
              title={getText('no-appointments')}
              subtitle={getText('check-later')}
              icon={<ClockIcon size={100} color={theme.palette.gray[300]} />}
              layout="vertical"
            />
          ),
        }}
        gridToolbarProps={{
          titleProps: {
            title: `${getText('canceled')}  ${getText('appointments')}`,
          },
        }}
      />
      {tooltipData && <Tooltip text={tooltipData.value} id={tooltipData.id} />}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  cellContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
  badgeText: {
    fontSize: 14,
    lineHeight: 20,
    paddingLeft: theme.getSpacing(1),
    paddingRight: theme.getSpacing(1),
    paddingVertical: theme.getSpacing(0.5),
  },
  title: {
    ...theme.fonts.regular,
    fontSize: 26,
    marginRight: theme.getSpacing(3),
    alignItems: 'center',
    display: 'flex',
    fontWeight: '600',
    zIndex: 10,
    marginBottom: -36,
  },
}));

interface BookingItem extends BookingDto {
  full_name?: string;
  date_of_birth?: string;
  duration?: number;
  service?: string;
}
export interface AppointmentsCancelledListProps {}

interface CellRendererProps {
  value: string;
  rowIndex: number;
}

interface TooltipProps {
  value: string;
  id: string;
}
