import React from 'react';
import {
  View,
  NativeSyntheticEvent,
  TextInputChangeEventData,
  GestureResponderEvent,
  Pressable,
} from 'react-native';
import { TextInput } from 'react-native-paper';
import { useTheme, makeStyles } from '../../theme';
import { TypeaheadNativeListItem } from './TypeaheadNativeListItem';
import { Text } from '../text';
import { LoadingIndicator } from '../loading-indicator';
import { SearchIcon } from '../../icons/SearchIcon';
import { Icon } from '../icon';
import { CheckIcon } from '../../icons/CheckIcon';
import { ScrollView } from 'react-native-gesture-handler';
import { uniqueWithMap } from './utils';
import { TypeaheadContext } from './context';
import { TypeaheadBaseItem, TypeaheadBaseProps } from './types';
import { getText } from '../../localization/localization';

export const TypeaheadNativeBase = <T extends string | TypeaheadBaseItem>(
  {
    options,
    defaultValue,
    emptyValue,
    hintMessage,
    disabled = false,
    multiple = false,
    onInputChange = (
      value: NativeSyntheticEvent<TextInputChangeEventData>,
    ) => {},
    asyncOptions,
    getOptionText = (option) =>
      (option as TypeaheadBaseItem).text ?? (option as string),
    getOptionValue = (option) =>
      (option as TypeaheadBaseItem).value ?? (option as string),
    onReturnKeyPressed = () => {},
    onClose = () => {},
    context,
  }: TypeaheadBaseProps<T> & {
    context?: React.ContextType<typeof TypeaheadContext>;
  },
  ref: React.Ref<TypeaheadNativeBaseMethods>,
) => {
  const theme = useTheme();
  const styles = useStyles();
  // checks
  if (options && asyncOptions)
    throw Error(
      'Typeahead can not implement both [options] and [asyncOptions]!',
    );

  const typeaheadContext = context ?? React.useContext(TypeaheadContext);

  const [selfOptions, setSelfOptions] = React.useState<Array<any>>(
    options ?? [],
  );
  const [selectedValues, setSelectedValues] = React.useState(
    uniqueWithMap(defaultValue, getOptionValue) as T[],
  );
  const [inputValue, setInputValue] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (options) setSelfOptions(options);
  }, [options]);

  // actions
  const handleOnSelection = (value: T) => {
    let newSelectedValue = !multiple
      ? [value]
      : (uniqueWithMap([...selectedValues, value], getOptionValue) as T[]);

    if (emptyValue)
      newSelectedValue = newSelectedValue.filter(
        (x) => getOptionValue(x) !== getOptionValue(emptyValue as T),
      );

    typeaheadContext.setSelectedItems(newSelectedValue);
    setSelectedValues(newSelectedValue);
  };

  const handleOnRemove = (value: T) => {
    const newSelectedValue = !multiple
      ? []
      : (uniqueWithMap(
          [
            ...selectedValues.filter(
              (x) => getOptionValue(x) !== getOptionValue(value),
            ),
          ],
          getOptionValue,
        ) as T[]);

    typeaheadContext.setSelectedItems(newSelectedValue);
    setSelectedValues(newSelectedValue);
  };

  const handleInputChange = async (
    value: NativeSyntheticEvent<TextInputChangeEventData>,
  ) => {
    const {
      nativeEvent: { text },
    } = value;
    setInputValue(text);

    if (asyncOptions && text) {
      setLoading(true);
      try {
        const newOptions = await asyncOptions(text);
        setSelfOptions(newOptions ?? []);
        setLoading(false);
      } catch (error) {
        // TODO: handle error that are not handled from asyncOptions
      }
    } else onInputChange(value);
  };

  const handleNoOptionPress = (e: GestureResponderEvent) => {
    if (!emptyValue) return;

    setSelectedValues([emptyValue]);
    typeaheadContext.setSelectedItems([emptyValue]);

    onClose();
  };

  const isWrittenValueSelected = selectedValues
    .map((sValue) => getOptionValue(sValue))
    .includes(inputValue);

  return (
    <View>
      <View
        testID={TypeaheadNativeBaseTestIDs.container}
        style={styles.container}
      >
        <View testID={TypeaheadNativeBaseTestIDs.search} style={styles.search}>
          {hintMessage && (
            <Text
              testID={TypeaheadNativeBaseTestIDs.hintText}
              style={styles.hintMessage}
            >
              {hintMessage}
            </Text>
          )}
          <TextInput
            placeholder={getText('search')}
            autoComplete="off"
            autoCapitalize="none"
            value={inputValue}
            onChange={handleInputChange}
            style={styles.textInput}
            mode="outlined"
            testID={TypeaheadNativeBaseTestIDs.input}
            activeOutlineColor={theme.colors.primary}
            left={
              <TextInput.Icon
                name={SearchIcon}
                color={theme.palette.gray[500]}
                size={22}
                forceTextInputFocus={false}
                style={{ top: 4 }}
              />
            }
            disabled={disabled}
            returnKeyType="done"
            onSubmitEditing={onReturnKeyPressed}
            children={undefined}
          />
        </View>
        {emptyValue ? (
          <View
            testID={TypeaheadNativeBaseTestIDs.emptyValue}
            style={styles.emptyContainer}
          >
            <Pressable onPress={handleNoOptionPress} style={styles.emptyButton}>
              <Text style={styles.emptyButtonText}>
                {getOptionText(emptyValue)}
              </Text>
            </Pressable>
          </View>
        ) : null}
        <View testID={TypeaheadNativeBaseTestIDs.listContainer}>
          {loading ? (
            <View
              testID={TypeaheadNativeBaseTestIDs.loadingIndicator}
              style={styles.loadingIndicator}
            >
              <LoadingIndicator />
            </View>
          ) : (
            <ScrollView>
              <View testID={TypeaheadNativeBaseTestIDs.list}>
                {inputValue ? (
                  <TypeaheadNativeListItem
                    key={'useWrittenValue'}
                    option={{ text: inputValue, value: inputValue }}
                    getOptionText={(value) =>
                      isWrittenValueSelected
                        ? value.text
                        : `${getText('use')} "${value.text}"`
                    }
                    rightIcon={
                      isWrittenValueSelected ? (
                        <Icon
                          size={16}
                          icon={CheckIcon}
                          color={theme.palette.success[600]}
                        />
                      ) : null
                    }
                    disabled={disabled}
                    onPress={
                      isWrittenValueSelected
                        ? handleOnRemove
                        : handleOnSelection
                    }
                  />
                ) : selfOptions.length < 1 ? (
                  selectedValues
                    .filter(
                      (x) =>
                        getOptionValue(x) !==
                        getOptionValue(emptyValue ?? ({} as T)),
                    )
                    .map((selected, index) => (
                      <TypeaheadNativeListItem
                        key={index}
                        option={selected}
                        rightIcon={
                          <Icon
                            size={16}
                            icon={CheckIcon}
                            color={theme.colors.primary}
                          />
                        }
                        getOptionText={getOptionText}
                        disabled={disabled}
                        onPress={handleOnRemove}
                        isLast={index === selfOptions.length - 1}
                      />
                    ))
                ) : null}
                {selfOptions.map((option, index) => {
                  const isSelected = selectedValues
                    .map((sValue) => getOptionValue(sValue))
                    .includes(getOptionValue(option));

                  return (
                    <TypeaheadNativeListItem
                      key={index}
                      option={option}
                      rightIcon={
                        isSelected ? (
                          <Icon
                            size={16}
                            icon={CheckIcon}
                            color={theme.colors.primary}
                          />
                        ) : null
                      }
                      getOptionText={getOptionText}
                      disabled={disabled}
                      onPress={isSelected ? handleOnRemove : handleOnSelection}
                      isLast={index === selfOptions.length - 1}
                    />
                  );
                })}
              </View>
            </ScrollView>
          )}
        </View>
      </View>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.getSpacing(3),
  },

  search: {
    marginBottom: theme.getSpacing(2),
  },
  textInput: {
    height: 44,
  },
  emptyContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    marginBottom: theme.getSpacing(2),
    marginTop: 0,
  },
  emptyButton: {
    height: 44,
    borderColor: theme.palette.gray[500],
    borderWidth: 1,
    borderRadius: theme.roundness,
  },
  emptyButtonText: {
    lineHeight: 24,
    fontWeight: '500',
    fontSize: 16,
    color: theme.palette.gray['700'],
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: theme.getSpacing(2),
    paddingRight: theme.getSpacing(2),
  },
  hintMessage: {
    color: theme.palette.gray[700],
    fontSize: 14,
    fontWeight: '500',
  },
  errorMessage: {
    color: theme.palette.error[600],
    fontSize: 14,
    marginTop: theme.getSpacing(1),
  },
  loadingIndicator: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 44,
  },
}));

export const TypeaheadNativeBaseTestIDs = {
  container: 'typeahead-native-container',
  label: 'typeahead-native-label',
  noneText: 'typeahead-native-none-text',
  hintText: 'typeahead-native-hint-text',
  selected: 'typeahead-native-selected',
  search: 'typeahead-native-search',
  list: 'typeahead-native-list',
  listContainer: 'typeahead-native-list-container',
  input: 'typeahead-native-input',
  loadingIndicator: 'typeahead-native-loading-indicator',
  emptyValue: 'typeahead-native-empty-value',
};

export interface TypeaheadNativeBaseMethods {
  onCloseCallback: Function;
}
