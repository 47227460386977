import { makeStyles } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import { Button, ButtonProps } from 'assets/components/button';
import { Text } from 'assets/components/text';
import { PlusCircleIcon } from 'assets/icons';
import { IconProps } from 'react-toastify';

const SettingsSection: FunctionComponent<
  PropsWithChildren<SettingsSectionProps>
> = ({
  title,
  subtitle,
  contentHeader,
  contentData,
  contentItem: ContentItemComponent,
  customContentHeaderComponent: CustomHeaderComponent,
  customTypeModal: CustomModal,
}) => {
  const styles = useStyles();
  return (
    <>
      <View style={styles.content}>
        <View style={{ flex: 1 }}>
          <Text style={styles.sectionTitle}>{title}</Text>
          {subtitle && <Text style={styles.sectionSubtitle}>{subtitle}</Text>}
        </View>

        <View style={{ flex: 3 }}>
          {/* Using the provided Functional Component as section header if provided*/}
          {CustomHeaderComponent && <CustomHeaderComponent />}

          {/* Using the provided default section header options/components */}
          {!CustomHeaderComponent && contentHeader && (
            <View style={[styles.contentHeader, contentHeader.containerStyle]}>
              <Text style={styles.sectionContentTitle}>
                {contentHeader.title}
              </Text>

              {contentHeader.button && (
                <Button
                  style={styles.buttonBorderColor}
                  labelStyle={styles.buttonLabel}
                  icon={PlusCircleIcon}
                  onPress={contentHeader.button.onPress}
                  {...contentHeader.button}
                >
                  {contentHeader.button.buttonTitle}
                </Button>
              )}
            </View>
          )}

          {contentData.map((item, index) => (
            <ContentItemComponent key={item?.id || index} {...item} />
          ))}
        </View>
      </View>
      {CustomModal && <CustomModal />}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  content: {
    flexDirection: 'row',
    gap: theme.getSpacing(1),
    alignItems: 'baseline',
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 1,
    paddingBottom: theme.getSpacing(4) + theme.getSpacing(2),
    marginBottom: theme.getSpacing(3),
  },
  sectionTitle: {
    ...theme.fonts.medium,
    fontSize: 16,
    lineHeight: 18,
    fontWeight: '700',
    paddingBottom: theme.getSpacing(2),
  },
  sectionContentTitle: {
    ...theme.fonts.medium,
    fontSize: 14,
    lineHeight: 16,
  },
  sectionSubtitle: {
    ...theme.fonts.regular,
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[700],
  },
  contentHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomColor: theme.palette.gray[600],
    borderBottomWidth: 1,
    paddingBottom: theme.getSpacing(2),
    paddingHorizontal: theme.getSpacing(1),
  },
  buttonLabel: {
    color: theme.palette.primary[500],
    marginHorizontal: theme.getSpacing(1),
    marginTop: 0,
    marginBottom: 0,
    marginRight: 0,
  },
  buttonBorderColor: {
    borderColor: 'transparent',
  },
}));

interface ContentHeaderButtonProps extends ButtonProps {
  buttonTitle?: string;
}

interface ContentHeaderProps {
  title: string;
  button?: ContentHeaderButtonProps;
  containerStyle?: StyleProp<ViewStyle>;
  icon?: IconProps;
}
interface SettingsSectionProps {
  title: string;
  subtitle?: string;
  contentHeader?: ContentHeaderProps;
  customContentHeaderComponent?: FunctionComponent<PropsWithChildren<any>>;
  contentData: any[];
  contentItem: FunctionComponent<PropsWithChildren<any>>;
  customTypeModal?: FunctionComponent<PropsWithChildren<any>>;
}

export default SettingsSection;
