import PatientFormModal from '../PatientFormModal/PatientFormModal';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IPatientForm } from '../PatientFormModal/types';
import {
  CreateAddressDto,
  CreatePatientRecordDto,
  Gender,
  PatientRecordDto,
} from '@digitalpharmacist/patient-service-client-axios';
import {
  initLocationPatientRecord,
  addPatientRecord,
} from './addPatientModalActions';
import { formatDate } from '../../common/datetime-utils';
import usePatientStore from '../../screens/patients/patient-store';
import { LocationPatientRecordDtoWithFullName } from '../../screens/patients/patient-types';

interface AddPatientModalProps {
  show: boolean;
  setShowAddPatientModal: (arg: boolean) => void;
  locationId: string;
  pharmacyId: string;
  successCB?: (patientRecord: PatientRecordDto) => void;
}

const AddPatientModal: FC<AddPatientModalProps> = ({
  show,
  setShowAddPatientModal,
  locationId,
  pharmacyId,
  successCB,
}) => {
  const methods = useForm<IPatientForm>();
  const [error, setError] = useState<string>('');
  const { patients, setPatients } = usePatientStore();

  useEffect(() => {
    if (show) {
      methods.reset();
      setError('');
    }
  }, [methods, show]);

  const onSubmit = async (data: IPatientForm) => {
    try {
      const patientRecord = populatePatientRecord(data);

      const createdPatientRecord = await addPatientRecord(patientRecord);

      if (createdPatientRecord) {
        const createdPatientRecordFromInit = await initLocationPatientRecord(
          createdPatientRecord.id,
          locationId,
        );
        if (!createdPatientRecordFromInit) {
          alert(
            'The patient record was created and the location patient record was not',
          );
        } else {
          const patient = convertToLocationPatientRecord(
            createdPatientRecordFromInit,
          );
          setPatients([patient, ...patients]);
        }
        setShowAddPatientModal(false);
        if (successCB) {
          const patientRecord = {
            ...createdPatientRecord,
            location_patient_records:
              createdPatientRecordFromInit?.location_patient_records || [],
          };
          successCB(patientRecord);
        }
      } else {
        alert('Failed to create patient records');
      }
    } catch (err) {
      if (err === 'Conflict') {
        setError('This patient already exists');
      } else {
        setError('Something went wrong!');
      }
    }
  };

  function populatePatientRecord(data: IPatientForm): CreatePatientRecordDto {
    return {
      pharmacy_id: pharmacyId,
      first_name: data.first_name,
      last_name: data.last_name,
      phone: data.phone,
      date_of_birth: formatDate(data.date_of_birth, 'YYYY-MM-DD'),
      gender:
        Gender.Male == data.gender
          ? Gender.Male
          : Gender.Female == data.gender
          ? Gender.Female
          : undefined,
      email: data.email,
      address: getAddress(data),
      preferred_language: data.preferred_language
        ? data.preferred_language.value
        : undefined,
      allergies: data.allergies?.length ? data.allergies.split(',') : [],
      medical_conditions: data.conditions ? data.conditions.split(',') : [],
      prefers_med_sync: data.prefers_med_sync,
      prefers_easy_open_bottle_caps: data.prefers_easy_open_bottle_caps,
      notify_by_sms: data.notify_by_sms,
      notify_by_voice: data.notify_by_voice,
      notify_by_email: data.notify_by_email,
    };
  }

  function getAddress(data: IPatientForm): CreateAddressDto | undefined {
    const isAllAddressFieldsFilled =
      data.street1 &&
      data.city &&
      data.state?.value &&
      data.postal_code &&
      data.country;

    if (isAllAddressFieldsFilled) {
      return {
        address1: data.street1 ?? '',
        address2: data.street2 ? data.street2 : undefined,
        city: data.city ?? '',
        state: data.state?.value ?? '',
        postal_code: data.postal_code ?? '',
        country: data.country ?? '',
      };
    }
  }

  function convertToLocationPatientRecord(
    patientRecord: PatientRecordDto,
  ): LocationPatientRecordDtoWithFullName {
    return {
      id: `temporary_id_${Date.now()}`,
      location_id: locationId,
      pharmacy_id: pharmacyId,
      patient_record_id: patientRecord.id,
      first_name: patientRecord.first_name,
      last_name: patientRecord.last_name,
      full_name: patientRecord.first_name + ' ' + patientRecord.last_name,
      phone: patientRecord.phone,
      external_id: null,
      date_of_birth: patientRecord.date_of_birth,
      created_at: '',
      updated_at: '',
      is_verified: false,
    };
  }

  return (
    <PatientFormModal
      title="Add patient"
      methods={methods}
      show={show}
      setShowPatientFormModal={setShowAddPatientModal}
      onSubmit={onSubmit}
      error={error}
    />
  );
};

export default AddPatientModal;
