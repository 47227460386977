import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import {
  Controller,
  RegisterOptions,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { View } from 'react-native';
import { Text } from 'assets/components/text';
import { makeStyles } from 'assets/theme';
import { zIndexAuto } from '../../common/theme';
import { DateTimePickerField } from '../../components/DateTimePickerField';

export const TimeRangeInput: FunctionComponent<
  PropsWithChildren<TimeRangeInputProps>
> = ({ name, disabled, startTimeRules, endTimeRules }) => {
  const formContext = useFormContext();
  if (!formContext) {
    throw new Error('Date Time Picker must have a parent form context');
  }

  const { control, setValue } = formContext;
  const startTime = useWatch({ name: `${name}.start` });
  const endTime = useWatch({ name: `${name}.end` });
  const styles = useStyles();

  useEffect(() => {
    if (startTime || endTime) {
      setValue(name, {
        start: startTime,
        end: endTime,
      });
    }
  }, [startTime, endTime]);

  return (
    <View style={{ zIndex: zIndexAuto, flex: 1 }}>
      <Controller
        name={name}
        control={control}
        render={() => (
          <View style={styles.inputsContainer}>
            <View style={styles.input}>
              <DateTimePickerField
                name={`${name}.start`}
                type="time"
                disabled={disabled}
                rules={startTimeRules}
                maxTime={endTime}
              />
            </View>
            <View style={styles.separator}>
              <Text>-</Text>
            </View>
            <View style={styles.input}>
              <DateTimePickerField
                name={`${name}.end`}
                type="time"
                disabled={disabled || !startTime}
                rules={endTimeRules}
                minTime={startTime}
              />
            </View>
          </View>
        )}
      />
    </View>
  );
};

export interface TimeRangeInputProps {
  name: string;
  disabled?: boolean;
  startTimeRules?: RegisterOptions;
  endTimeRules?: RegisterOptions;
}

const useStyles = makeStyles((theme) => ({
  inputsContainer: {
    zIndex: zIndexAuto,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  input: {
    flex: 1,
    zIndex: zIndexAuto,
  },
  separator: {
    paddingHorizontal: theme.getSpacing(1),
  },
}));
