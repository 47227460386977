import { AxiosRequestConfig } from 'axios';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';
import {
  PATIENT_API_BASE_URL,
  PRESCRIPTION_API_BASE_URL,
} from '../common/constants';
import {
  PharmacistApi as PrescriptionServiceClient,
  RefillItemDto,
} from '@digitalpharmacist/prescription-service-client-axios';
import {
  LocationRefillSubmissions,
  SidebarDetails,
} from '../screens/refill-submissions/refill-submission-types';
import {
  DefaultApi as PatientService,
  PatientRecordDto,
} from '@digitalpharmacist/patient-service-client-axios';
import { AXIOS_DEFAULT_REQUEST_CONFIG } from '../common/axios-default-request-config';

export interface IPrescriptionService {
  findRefills(locationId: string): Promise<RefillItemDto[]>;
}
const patientService = new PatientService(undefined, PATIENT_API_BASE_URL);

export class PrescriptionService
  extends BaseApiService
  implements IPrescriptionService
{
  private prescriptionServiceClient: PrescriptionServiceClient;

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
    this.prescriptionServiceClient = new PrescriptionServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );
  }

  async findPrescriptionRefills(
    locationId: string,
  ): Promise<LocationRefillSubmissions[]> {
    const prescriptions =
      await this.prescriptionServiceClient.refillsFindByLocation(locationId);

    const refillSubmissions: LocationRefillSubmissions[] = [];
    for (const item of prescriptions.data) {
      refillSubmissions.push({
        prescription_id: item.prescription_id,
        prescription: item.rx_number,
        prescription_name: item.drug_name,
        drug_name: item.drug_name,
        patient_id: item.patient_id,
        full_name: `${item.patient_first_name} ${item.patient_last_name}`,
        channel: item.ingress_method,
        method: item.fulfillment_method,
        status: item.rx_status,
        date_submitted: item.submitted_at,
        location_id: locationId,
        refill_comments: item.notes,
      } as LocationRefillSubmissions);
    }
    return refillSubmissions;
  }

  fillPatientDetailsSidebar = async (
    selectedRefillSubmission: LocationRefillSubmissions,
  ): Promise<SidebarDetails> => {
    const patientServiceResponse =
      await patientService.locationPatientRecordFindOne(
        selectedRefillSubmission.location_id,
        selectedRefillSubmission.patient_id,
      );

    const patient = patientServiceResponse.data;

    return {
      // PrescriptionDetails
      prescription_id: selectedRefillSubmission.prescription_id,
      prescription_number: selectedRefillSubmission.prescription,
      prescription_name: selectedRefillSubmission.prescription_name,
      channel: selectedRefillSubmission.channel,
      method: selectedRefillSubmission.method,
      status: selectedRefillSubmission.status,
      date_submitted: selectedRefillSubmission.date_submitted,
      refill_comments: selectedRefillSubmission.refill_comments,
      patient_id: patient.id,
      full_name: `${patient.first_name} ${patient.last_name}`,
      date_of_birth: patient.date_of_birth,
      phone: patient.phone,
      email: patient.email,
      address: patient.address?.address1,
    } as SidebarDetails;
  };

  async findRefills(locationId: string): Promise<RefillItemDto[]> {
    const response =
      await this.prescriptionServiceClient.refillsFindByLocation(locationId);
    return response.data;
  }
}

export default new PrescriptionService(
  PRESCRIPTION_API_BASE_URL,
  AXIOS_DEFAULT_REQUEST_CONFIG,
  true,
);
