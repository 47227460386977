import Constants from 'expo-constants';
import { Environment } from '../src/ampli';

export const API_GATEWAY_BASE_URL: string =
  Constants.manifest?.extra?.API_GATEWAY_BASE_URL;

export const COGNITO_REGION: string = Constants.manifest?.extra?.COGNITO_REGION;

export const COGNITO_USER_POOL_ID: string =
  Constants.manifest?.extra?.COGNITO_USER_POOL_ID;

export const COGNITO_USER_POOL_WEB_CLIENT_ID: string =
  Constants.manifest?.extra?.COGNITO_USER_POOL_WEB_CLIENT_ID;

export const CONFIG_ENGINE_BASE_URL: string =
  Constants.manifest?.extra?.CONFIG_ENGINE_BASE_URL;

export const ERROR_LOGGER = {
  dsn: Constants.manifest?.extra?.SENTRY_DSN as string,
};

export const ENVIRONMENT: string = Constants.manifest?.extra?.ENVIRONMENT;

export const AMPLI_ENVIRONMENT: Environment =
  Constants.manifest?.extra?.AMPLI_ENVIRONMENT;

export const USER_API_BASE_URL: string =
  Constants.manifest?.extra?.USER_API_BASE_URL;

export const FORMS_API_BASE_URL: string =
  Constants.manifest?.extra?.FORMS_API_BASE_URL;

export const TASKS_API_BASE_URL: string =
  Constants.manifest?.extra?.TASKS_API_BASE_URL;

export const PATIENT_API_BASE_URL: string =
  Constants.manifest?.extra?.PATIENT_API_BASE_URL;

export const PRESCRIPTION_API_BASE_URL: string =
  Constants.manifest?.extra?.PRESCRIPTION_API_BASE_URL;

export const ROLE_API_BASE_URL: string =
  Constants.manifest?.extra?.ROLE_API_BASE_URL;

export const INBOX_SOCKET_URL: string =
  Constants.manifest?.extra?.INBOX_SOCKET_URL;

export const UNIFIED_COMMS_SERVICE_BASE_URL: string =
  Constants.manifest?.extra?.UNIFIED_COMMS_SERVICE_BASE_URL;

export const FILE_STORAGE_SERVICE_BASE_URL: string =
  Constants.manifest?.extra?.FILE_STORAGE_SERVICE_BASE_URL;

export const JOTFORM_API_BASE_URL: string =
  Constants.manifest?.extra?.JOTFORM_API_BASE_URL;

export const APPOINTMENT_API_BASE_URL: string =
  Constants.manifest?.extra?.APPOINTMENT_API_BASE_URL;

export const PHARMACY_API_BASE_URL: string =
  Constants.manifest?.extra?.PHARMACY_API_BASE_URL;

export const GOOGLE_WEB_CLIENT_ID: string =
  Constants.manifest?.extra?.GOOGLE_WEB_CLIENT_ID;
export const GOOGLE_EXPO_CLIENT_ID: string =
  Constants.manifest?.extra?.GOOGLE_EXPO_CLIENT_ID;
export const GOOGLE_IOS_CLIENT_ID: string =
  Constants.manifest?.extra?.GOOGLE_IOS_CLIENT_ID;
export const GOOGLE_ANDROID_CLIENT_ID: string =
  Constants.manifest?.extra?.GOOGLE_ANDROID_CLIENT_ID;

export const GSTC_LICENSE_KEY: string =
  Constants.manifest?.extra?.GSTC_LICENSE_KEY;
export const VUCA_API_BASE_URL: string =
  Constants.manifest?.extra?.VUCA_API_BASE_URL;
export const PATIENT_BASE_URL: string =
  Constants.manifest?.extra?.PATIENT_BASE_URL;
export const PATIENT_APP_BASE_URL: string =
  Constants.manifest?.extra?.PATIENT_APP_BASE_URL;
