import React, { FunctionComponent, useCallback, useRef, useState } from 'react';
import { View } from 'react-native';
import { GridReadyEvent, GridApi } from '@ag-grid-community/core';
import { useFocusEffect } from '@react-navigation/native';
import {
  AppointmentTypeDto,
  BookingDto,
  BookingPageDto,
} from '@digitalpharmacist/appointment-service-client-axios';
import { makeStyles, useTheme } from 'assets/theme';
import { Text } from 'assets/components/text';
import { DataGrid } from 'assets/components/data-grid';
import { CheckIcon, ClockIcon } from 'assets/icons';

import NoResultsOverlay from '../../components/NoResultsOverlay';
import AppointmentService from '../../api/AppointmentService';
import { LoadingOverlay } from '../../components/LoadingOverlay';
import {
  calculatePatientAge,
  DEFAULT_UTC_OFFSET,
  formatDate,
  formatDateTimeApi,
  formatUTCToRelative,
} from '../../common/datetime-utils';
import { ColoredBadge } from '../../components/ColoredBadge';
import {
  AppointmentsFindParams,
  minutesDifference,
  STATUS_STYLE_MAPPING,
} from '../appointments-list/appointments-list.utils';
import Select from 'react-select';
import { showAppointmentDetails } from '../appointments-list/appointments-list-actions';
import { getText } from '../../../../../packages/assets/localization/localization';
import { useServicesListState } from '../services-list/services-list-store';
import { getServices } from '../services-list/services-list-actions';
import { useProSidebar } from 'react-pro-sidebar';
import { AppointmentDetailsSidebar } from '../appointments-list/AppointmentDetailsSidebar';
import moment from 'moment';
import { Tooltip } from '../../components/Tooltip';
import { EllipsisTextRendererTooltip } from '../../common/EllipsisTextRendererTooltip';

const NoLoading: FunctionComponent = () => {
  return <View style={{ height: '100%' }}></View>;
};

const StatusRenderer = (props: { data: BookingItem }) => {
  const styles = useStyles();
  const rowData = props.data;
  const isPast = moment(moment(rowData.endTime)).isBefore(moment());

  const badgeData = isPast
    ? STATUS_STYLE_MAPPING.PAST
    : STATUS_STYLE_MAPPING[rowData.status];

  return (
    <View style={styles.cellContainer}>
      <ColoredBadge {...badgeData} textStyle={styles.badgeText} />
    </View>
  );
};

export const PastAppointmentsList: FunctionComponent = () => {
  const theme = useTheme();
  const styles = useStyles();
  const [paginatedDatasource] = useState(
    AppointmentService.getAppointmentsPaginatedDatasource(
      `&max_start_date=${formatDateTimeApi(moment())}`,
    ),
  );
  const [tooltipData, setTooltipData] = useState<TooltipProps>();

  const gridApiRef = useRef<GridApi>();
  const { services, status: servicesStatus } = useServicesListState(
    (state) => ({
      ...state,
      services: state.services?.filter((service) => service.enabled),
    }),
  );
  const isLoading = servicesStatus === 'loading';
  const { collapseSidebar } = useProSidebar();

  const [sortingValue, setSortingValue] = useState<
    AppointmentTypeDto | undefined | null
  >();

  const selectTransform = (menuIsOpen?: boolean) =>
    menuIsOpen ? 'rotate(180deg)' : undefined;

  const [columnDefs] = useState([
    {
      field: 'full_name',
      colId: 'patient_record_last_name',
      sortable: true,
      headerName: getText('name'),
      cellRenderer: ({ value, rowIndex }: CellRendererProps) => (
        <EllipsisTextRendererTooltip
          value={value}
          rowIndex={rowIndex}
          setTooltipData={setTooltipData}
        />
      ),
    },
    {
      field: 'patient_record_date_of_birth',
      headerName: getText('birthdate'),
      cellRenderer: ({ value, rowIndex }: CellRendererProps) => (
        <EllipsisTextRendererTooltip
          value={
            value && `${formatDate(value)} (${calculatePatientAge(value)})`
          }
          rowIndex={rowIndex}
          setTooltipData={setTooltipData}
        />
      ),
    },
    {
      field: 'title',
      headerName: getText('service'),
      cellRenderer: ({ value, rowIndex }: CellRendererProps) => (
        <EllipsisTextRendererTooltip
          value={value}
          rowIndex={rowIndex}
          setTooltipData={setTooltipData}
        />
      ),
    },
    {
      field: 'appointment_group_type',
      headerName: getText('service-category'),
      cellRenderer: ({ value, rowIndex }: CellRendererProps) => (
        <EllipsisTextRendererTooltip
          value={value}
          rowIndex={rowIndex}
          setTooltipData={setTooltipData}
        />
      ),
    },
    {
      maxWidth: 140,
      field: 'duration',
      headerName: getText('duration'),
      cellRenderer: ({ value, rowIndex }: CellRendererProps) => (
        <EllipsisTextRendererTooltip
          value={`${value} min`}
          rowIndex={rowIndex}
          setTooltipData={setTooltipData}
        />
      ),
    },
    // TODO: This is not yet implemented on the back-end, the hard-coded value needs to be replaced with the API data.
    {
      maxWidth: 150,
      field: 'location',
      headerName: getText('venue'),
      cellRenderer: ({ value, rowIndex }: CellRendererProps) => (
        <EllipsisTextRendererTooltip
          value={getText('venue-in-person')}
          rowIndex={rowIndex}
          setTooltipData={setTooltipData}
        />
      ),
    },
    {
      maxWidth: 150,
      field: 'status',
      headerName: getText('status'),
      cellRenderer: StatusRenderer,
      cellStyle: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-start',
      },
    },
    {
      maxWidth: 160,
      field: 'startTime',
      colId: 'start_time',
      sortable: true,
      cellRenderer: ({ value, rowIndex }: CellRendererProps) => (
        <EllipsisTextRendererTooltip
          value={formatUTCToRelative(
            value,
            undefined,
            true,
            'US',
            DEFAULT_UTC_OFFSET,
          )}
          rowIndex={rowIndex}
          setTooltipData={setTooltipData}
        />
      ),
      headerName: getText('time'),
      headerClass: 'data-grid-header-right-aligned',
      cellClass: 'data-grid-cell-right-aligned',
    },
  ]);

  useFocusEffect(
    useCallback(() => {
      if (!services?.length) {
        void getServices({ withoutNextAvailableSlot: true });
      }
    }, [services?.length]),
  );

  useFocusEffect(
    useCallback(() => {
      gridApiRef.current?.dispatchEvent({ type: 'filterChanged' });
    }, [sortingValue?.id]),
  );

  const handleCancel = () => {
    if (gridApiRef.current) {
      gridApiRef.current.refreshServerSideStore();
    }
  };

  return (
    <>
      <Select
        menuPlacement="auto"
        value={sortingValue}
        isSearchable
        isClearable
        menuPortalTarget={document.body}
        placeholder={`${getText('select')} ${getText('service').toLowerCase()}`}
        aria-label="Select"
        isDisabled={isLoading}
        isLoading={isLoading}
        styles={{
          menu: (provided) => ({ ...provided }),
          dropdownIndicator: (base, state) => ({
            ...base,
            transition: 'all .2s ease',
            transform: selectTransform(state.selectProps.menuIsOpen),
          }),
          control: (base) => ({
            ...base,
            borderRadius: theme.roundness,
            fontFamily: theme.fonts.regular.fontFamily,
            width: '255px',
            textAlign: 'left',
            position: 'absolute',
            zIndex: 10,
            left: '320px',
          }),
          option: (base, state) => ({
            ...base,
            backgroundColor: state.isFocused
              ? theme.palette.primary[50]
              : undefined,
          }),
          singleValue: (provided) => ({
            ...provided,
            color: theme.palette.gray[100],
          }),
          valueContainer: (provided) => ({
            ...provided,
            paddingTop: theme.getSpacing(1),
            paddingBottom: theme.getSpacing(1),
          }),
        }}
        components={{
          IndicatorSeparator: () => null,
        }}
        options={
          services?.length
            ? services.map((option) => ({
                ...option,
                label: (
                  <View style={styles.options}>
                    <Text style={styles.optionsLabel}>{option.title}</Text>
                    {sortingValue?.id === option.id && (
                      <View style={styles.checkIcon}>
                        <CheckIcon size={20} color={theme.colors.primary} />
                      </View>
                    )}
                  </View>
                ),
              }))
            : []
        }
        onChange={(option) => setSortingValue(option ?? undefined)}
      />
      <View style={{ flex: 1, flexDirection: 'row' }}>
        <View style={{ flex: 1 }}>
          <DataGrid
            gridOptions={{
              onGridReady(event: GridReadyEvent) {
                gridApiRef.current = event.api;
              },
              columnDefs: columnDefs,
              enableCellTextSelection: true,
              suppressRowClickSelection: true,
              suppressMovableColumns: true,
              suppressContextMenu: true,
              defaultColDef: { sortable: false, menuTabs: [] },
              pagination: true,
              paginationPageSize: 10,
              cacheBlockSize: 10,
              rowModelType: 'serverSide',
              serverSideStoreType: 'partial',
              serverSideDatasource: paginatedDatasource,
              loadingCellRendererSelector: (params) => {
                // This is a temporary workaround to only show one loading indicator per table row
                // What we want to do in the future is create a RowLoadingPlaceholder which would indicate the loading state for the row
                if (
                  params.rowIndex == 0 ||
                  (params.rowIndex % 5 == 0 && params.rowIndex % 10 !== 0)
                ) {
                  return {
                    component: LoadingOverlay,
                  };
                }

                return {
                  component: NoLoading,
                };
              },
              context: {
                transformResponse(params: BookingPageDto) {
                  const { results, total } = params;

                  const resultsWithUserData = results.map((x) => {
                    return {
                      ...x,
                      full_name: `${x.patient_record_first_name} ${x.patient_record_last_name}`,
                      duration: minutesDifference(x.endTime, x.startTime),
                    };
                  });

                  return {
                    total,
                    results: resultsWithUserData,
                  };
                },
                transformRequest(params: AppointmentsFindParams) {
                  return {
                    ...params,
                    appointment_type_id: sortingValue?.id,
                  };
                },
              },
              noRowsOverlayComponent: () => (
                <NoResultsOverlay
                  title={getText('no-appointments')}
                  subtitle={getText('check-later')}
                  icon={
                    <ClockIcon size={100} color={theme.palette.gray[300]} />
                  }
                  layout="vertical"
                />
              ),
              onCellClicked(event) {
                // We don't want to open the sidepanel if the user clicked on one of the actions buttons
                // unfortunately ag-grid propagates this event no matter what (eg. suppressRowClickSelection)
                // sidepanel won't trigger if the user clicks on the padding surrounding the action buttons @rtud
                if (event.column && event.column.getColId() !== 'actions') {
                  showAppointmentDetails(event.data.id);
                  collapseSidebar(false);
                }
              },
            }}
            gridToolbarProps={{
              titleProps: {
                title: `${getText('past')}  ${getText('appointments')}`,
              },
            }}
          />
        </View>
        {tooltipData && (
          <Tooltip text={tooltipData.value} id={tooltipData.id} />
        )}
        <AppointmentDetailsSidebar onCancel={handleCancel} isReadOnly={true} />
      </View>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  subTitle: {
    marginTop: theme.getSpacing(4),
    fontSize: 20,
    marginBottom: theme.getSpacing(2),
  },
  cellContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
  badgeText: {
    fontSize: 14,
    lineHeight: 20,
    paddingLeft: theme.getSpacing(1),
    paddingRight: theme.getSpacing(1),
    paddingVertical: theme.getSpacing(0.5),
  },
  title: {
    ...theme.fonts.regular,
    fontSize: 26,
    marginRight: theme.getSpacing(3),
    alignItems: 'center',
    display: 'flex',
    fontWeight: '600',
    zIndex: 10,
    marginBottom: -36,
  },
  label: {
    color: theme.palette.gray[700],
    fontSize: 14,
    marginBottom: theme.getSpacing(1),
  },
  options: {
    flexDirection: 'row',
  },
  optionsLabel: {
    flex: 9,
  },
  checkIcon: {
    flex: 1,
  },
}));

interface BookingItem extends BookingDto {
  full_name?: string;
  date_of_birth?: string;
  duration?: number;
  service?: string;
}

interface CellRendererProps {
  value: string;
  rowIndex: number;
}

interface TooltipProps {
  value: string;
  id: string;
}
